import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { API_URL } from '../config/app.config';
import { joinUrl } from '../util/join-url';
import { sortBy } from '../util/sort-by';
import { KpiCategory } from './kpi-category';

@Injectable({
  providedIn: 'root',
})
export class KpiCategoryService {
  private readonly categoryUrl = 'backend/category';
  private readonly $retrieveAll = this.http
    .get<KpiCategory[]>(joinUrl(this.apiUrl, this.categoryUrl, 'all'))
    .pipe(
      map((categories) => sortBy(categories, 'position')),
      shareReplay(1),
    );

  constructor(
    @Inject(API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  public retrieveAll(): Observable<KpiCategory[]> {
    return this.$retrieveAll;
  }

  public retrieveOne(id: string) {
    return this.http.get<KpiCategory>(joinUrl(this.apiUrl, this.categoryUrl, id));
  }

  public save(category: KpiCategory) {
    return this.http.post(joinUrl(this.apiUrl, this.categoryUrl), category, {
      responseType: 'text',
    });
  }
}
