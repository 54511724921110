export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending',
}

export function sortBy<T>(items: T[], key: keyof T, order = SortOrder.Ascending) {
  const factor = order === SortOrder.Ascending ? 1 : -1;
  return [...items].sort((a, b) => (a[key] > b[key] ? factor : b[key] > a[key] ? -factor : 0));
}

export function sortByValue<T>(items: T[], order = SortOrder.Ascending) {
  const factor = order === SortOrder.Ascending ? 1 : -1;
  return [...items].sort((a, b) => (a > b ? factor : b > a ? -factor : 0));
}
